<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <section
        class="bg-local bg-no-repeat bg-cover pb-20 md:pb-0"
        :style="backgroundImageStyle"
      >
        <div
          class="grid max-w-screen-xl px-4 pt-52 mx-auto md:gap-8 xl:gap-0 lg:pt-64 md:grid-cols-12"
        >
          <div class="mr-auto place-self-center md:col-span-7">
            <h1
              class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white"
            >
              {{ $t("header.title") }}
            </h1>
            <p
              class="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl"
            >
              {{ $t("header.subtitle") }}
            </p>
            <button
              href="#"
              class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
              @click="scrollTo"
            >
              {{ $t("header.cta") }}
              <svg
                class="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="hidden md:mt-0 md:col-span-5 md:flex">
            <img src="../assets/header_mrhentai.png" alt="mockup" />
          </div>
        </div>
      </section>
      <section class="pb-20 relative bg-pink-300">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 81px"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-pink-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="pt-6 md:pt-32 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i class="fas fa-award"></i>
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ $t("column.column-1-title") }}
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    {{ $t("column.column-1-subtitle") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pt-6 md:pt-12 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400"
                  >
                    <i class="fas fa-retweet"></i>
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ $t("column.column-2-title") }}
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    {{ $t("column.column-2-subtitle") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pt-6 md:pt-24 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400"
                  >
                    <i class="fas fa-fingerprint"></i>
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ $t("column.column-3-title") }}
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    {{ $t("column.column-3-subtitle") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-blue-900">
        <div
          class="grid max-w-screen-xl px-4 py-40 mx-auto md:gap-8 xl:gap-0 lg:pt-40 md:grid-cols-12"
        >
          <div class="mr-auto hidden md:mt-0 md:col-span-6 md:flex">
            <img src="../assets/presentating_girl.png" alt="mockup" />
          </div>
          <div class="place-self-center md:col-span-6">
            <h1
              class="max-w-2xl mb-4 text-white text-2xl font-extrabold tracking-tight leading-none md:text-4xl"
            >
              {{ $t("box.title") }}
            </h1>
            <p
              class="max-w-2xl mb-6 text-white font-light lg:mb-8 md:text-lg lg:text-xl"
            >
              {{ $t("box.subtitle") }}
            </p>
            <button
              class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
              @click="scrollTo"
            >
              {{ $t("header.cta") }}
              <svg
                class="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section class="bg-white">
        <div
          class="grid max-w-screen-xl px-4 py-40 mx-auto md:gap-8 xl:gap-0 lg:pt-40 md:grid-cols-12"
        >
          <div class="mr-auto place-self-center md:col-span-6">
            <h1
              class="max-w-2xl mb-4 text-blue-900 text-2xl font-extrabold tracking-tight leading-none md:text-4xl"
            >
              {{ $t("hentaishop.title") }}
            </h1>
            <p
              class="max-w-2xl mb-6 text-blue-900 font-light lg:mb-8 md:text-lg lg:text-xl"
            >
              {{ $t("hentaishop.subtitle") }}
            </p>
            <ul
              class="list-inside list-disc max-w-2xl my-6 text-blue-900 font-light lg:mb-8 md:text-lg lg:text-xl"
            >
              <li class="mt-4">
                {{ $t("hentaishop.bullet-1") }}
              </li>
              <li class="mt-4">
                {{ $t("hentaishop.bullet-2") }}
              </li>
              <li class="mt-4">
                {{ $t("hentaishop.bullet-3") }}
              </li>
              <li class="mt-4">
                {{ $t("hentaishop.bullet-4") }}
              </li>
            </ul>
            <a
              href="https://thehentaishop.com"
              target="_blank"
              class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-blue-900 rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
            >
              {{ $t("hentaishop.cta") }}
              <svg
                class="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div class="hidden md:mt-0 md:col-span-6 md:flex">
            <img src="../assets/hentai_welcome_700x.webp" alt="mockup" />
          </div>
        </div>
      </section>
      <section class="bg-orange-200">
        <div
          class="grid max-w-screen-xl px-4 py-40 mx-auto md:gap-8 xl:gap-0 lg:pt-40 md:grid-cols-12"
        >
          <div class="hidden mr-auto md:mt-0 md:col-span-6 md:flex">
            <img src="../assets/mrhentai_stick.png" alt="mockup" />
          </div>
          <div class="place-self-center md:col-span-6">
            <h1
              class="max-w-2xl mb-4 text-blue-900 text-2xl font-extrabold tracking-tight leading-none md:text-4xl"
            >
              {{ $t("survey.title") }}
            </h1>
            <p
              class="max-w-2xl mb-6 font-bold text-blue-900 lg:mb-8 text-xl md:text-2xl"
            >
              {{ $t("survey.second-title") }}
            </p>
            <p
              class="max-w-2xl mb-6 text-blue-900 font-light lg:mb-8 md:text-lg lg:text-xl"
            >
              {{ $t("survey.subtitle") }}
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdJZB3KSTo_vcbwZ2g_ekWJT-0750jv4qt3_pph647HDb9WDg/viewform"
              target="_blank"
              class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-blue-900 rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
            >
              {{ $t("survey.cta") }}
              <svg
                class="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
      <section
        class="relative block py-24 lg:pt-0 bg-gray-900"
        ref="newsletterRef"
      >
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-md sm:text-center">
            <h2
              class="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl text-white"
            >
              {{ $t("newsletter.title") }}
            </h2>
            <p
              class="mx-auto mb-8 max-w-2xl font-light text-gray-300 md:mb-12 sm:text-xl"
            >
              {{ $t("newsletter.subtitle") }}
            </p>
          </div>

          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300"
          >
            <div class="flex-auto p-5 lg:p-10">
              <h4 class="text-2xl font-semibold">
                {{ $t("newsletter.secondtitle") }}
              </h4>

              <form action="https://formspree.io/f/xyzggqld" method="POST">
                <div class="relative w-full mb-3 mt-8">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="email"
                    >{{ $t("newsletter.email") }}</label
                  ><input
                    type="email"
                    name="email"
                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Email"
                    style="transition: all 0.15s ease 0s"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="full-name"
                    >{{ $t("newsletter.country") }}</label
                  >
                  <select
                    id="countries"
                    class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    name="country"
                  >
                    <option
                      v-for="item in countries"
                      :key="item.id"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="text-center mt-6">
                  <button
                    class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="submit"
                    style="transition: all 0.15s ease 0s"
                  >
                    {{ $t("newsletter.submit") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import countries from "../../countries.json";
import { ref } from "vue";

export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent,
  },
  setup() {
    const newsletterRef = ref(null);

    const scrollTo = () => {
      if (newsletterRef.value) {
        newsletterRef.value.scrollIntoView({ behavior: "smooth" });
      }
    };

    return {
      newsletterRef,
      scrollTo,
      countries,
      backgroundImage: require("../assets/background_header.jpg"),
      selectedCountry: ref(null),
    };
  },
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
};
</script>
