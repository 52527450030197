import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createWebHistory, createRouter } from "vue-router";
import messages from "@/i18n.json";
import "./index.css";

// views

import Landing from "./views/Landing.vue";
import Imprint from "./views/Imprint.vue";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";

// mouting point for the whole app

import App from "@/App.vue";

// routes

const routes = [
  {
    path: "/",
    component: Landing,
  },
  {
    path: "/imprint",
    component: Imprint,
  },
];

const i18n = createI18n({
  locale: "en",
  messages,
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.mount("#app");
