<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <a
          class="text-md font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap text-white"
          href="/"
          >thehentaibox</a
        >
      </div>
      <div>
        <button
          @click="toggleDropdown"
          class="ml-auto text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          type="button"
        >
          {{ $t("languageswitcher.name") }}
          <svg
            class="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        <!-- Dropdown menu -->
        <div
          v-if="dropdownOpen"
          class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
        >
          <ul
            class="py-2 text-sm text-gray-700"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <div
                class="block px-4 py-2 hover:bg-gray-100"
                @click.prevent="switchLanguage('en')"
              >
                {{ $t("languageswitcher.en") }}
              </div>
            </li>
            <li>
              <div
                class="block px-4 py-2 hover:bg-gray-100"
                @click.prevent="switchLanguage('de')"
              >
                {{ $t("languageswitcher.de") }}
              </div>
            </li>
            <li>
              <div
                class="block px-4 py-2 hover:bg-gray-100"
                @click.prevent="switchLanguage('fr')"
              >
                {{ $t("languageswitcher.fr") }}
              </div>
            </li>
            <li>
              <div
                class="block px-4 py-2 hover:bg-gray-100"
                @click.prevent="switchLanguage('nl')"
              >
                {{ $t("languageswitcher.nl") }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    switchLanguage(code) {
      this.dropdownOpen = false;
      this.$i18n.locale = code;
    },
  },
};
</script>
