<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <section class="bg-gray-600">
        <div class="py-64 px-4 mx-auto max-w-screen-xl text-center lg:px-12">
          <h1
            class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl"
          >
            Imprint
          </h1>
          <p
            class="mb-8 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48"
          >
            <b> Responsible for the content of www.thehentaibox.com: </b>
            <br />
            <br />
            h-box UG (haftungsbeschränkt)
            <br />
            Karl-Benz-Straße 9 40764 Langenfeld

            <br />Managing Director: Chi Yung Tang

            <br />
            Handelsregister Mönchengladbach HRB 19620
            <br />USt ID: DE335024121 <br />Contact:
            <br />
            <br />E-Mail: hello@thehentaishop.com
          </p>
          <h1
            class="mt-20 mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl"
          >
            Privacy Policy
          </h1>
          <p
            class="mb-8 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48"
          >
            We are not saving any data on this website.
          </p>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";

export default {
  name: "imprint-page",
  components: {
    NavbarComponent,
    FooterComponent,
  },
  setup() {
    return {};
  },
};
</script>
